
.identity-date-labels {
    /*text-align: center;*/
    /*padding-right:7rem;*/
}

.k-dropdownlist.identity-connection-settings {
    width:25rem;
    margin-left: 17%;
    height: 2.2rem;
    border-color: #476c75;
}

.k-dropdownlist.identity-scope {
    width:15rem;
    margin-left: 17%;
    height: 2.2rem;
    border-color: #476c75;
}
.k-dropdownlist.identity-summarization {
    width:15rem;
    margin-left: 17%;
    height: 2.2rem;
    border-color: #476c75;
}

.identity-layout {
    margin-left: 15%;
}

.k-daterangepicker.identity-date-picker {
    margin-left:17%;
    width: 80%;
}

.identity-connection-label {
    width: 30%;
    margin-left: 17%;
    padding-top: 2.5%;
}

.identity-checkbox  {
    margin-left:17%;
}

.identity-checkbox-spacing {
    margin-right:5rem;
}

.identity-create-segment-label {
    margin-left:17%;
    padding-right: .5rem;
}

.identity-create-segment-info-message {
    margin-left:17%;
}

.identity-controls-content {
    padding-top:1rem;
}