/*.k-button-icon.icon-plus {*/
/*    font-size: 1.3rem;*/
/*}*/
/*.k-button-icon.icon-edit {*/
/*    font-size: 1.3rem;*/
/*}*/
.k-button.new-edit-setting-button {
    background-color: var(--global-landing-page-color);
    width: 5rem;
    height: 2.2rem;
    border-color: #FFFFFF;
    color: #FFFFFF;
}
.k-button.new-edit-setting-button:hover {
    background-color: var(--global-landing-page-color);
}
.k-dropdownlist.connection-settings {
    width: 60rem;
    height: 2.2rem;
    border-color: var(--global-landing-page-color);
}
.k-button.file-browse-button {
    background-color: var(--global-landing-page-color);
    width: 7rem;
    height: 2.2rem;
    border-color: #FFFFFF;
    color: #FFFFFF;
}
.k-button.file-browse-button:hover {
    background-color: var(--global-landing-page-color);
}
.k-button.choose-a-file {
    /*width: 63rem;*/
    height: 2.2rem;
    background-color: #FFFFFF;
    color: lightgrey;
    margin-left: 0rem;
    margin-top:0.2rem;
}
.k-button.choose-a-file:hover {
    background-color: #FFFFFF;
}
.k-button.file-browse-button-1 {
    background-color: var(--global-landing-page-color);
    width: 5rem;
    height: 2.2rem;
    border-color: #FFFFFF;
    color: #FFFFFF;
    border-radius: 0.25rem;
}
.k-button.file-browse-button-1:hover {
    background-color: var(--global-landing-page-color);
}
.k-button-icon.icon-folder {
    font-size: 1.3rem;
}
.k-button-icon.icon-file {
    font-size: 1.3rem;
}
.label-prompts {
    padding-left: 3rem;
    padding-right: 2rem;
    font-size:18px;
    font-weight: bold;
}
.k-button.file-mapping-button {
    background-color: #00424D;
    width: 5rem;
    height: 2.2rem;
    border-color: #FFFFFF;
    color: #FFFFFF;
    border-radius: 0.25rem;
}
.k-button.file-mapping-button:hover{
    background-color: #00424D;
}
.k-button.file-chosen {
    width: 100%;
    height: 2.2rem;
    background-color: #FFFFFF;
    margin-right: 0.5rem;
    border-radius: 0.25rem;
}
.k-button.file-chosen:hover {
    background-color: #FFFFFF;
}
.icon-upload-sam.new-settings {
    padding-right: 1rem;
    padding-left: .5rem;
}
.icon-amazonaws:before {
    content: "\e900";
}
.icon-amazonaws.new-settings {
    padding-right: 1rem;
    padding-left: .5rem;
}


/*Added styles for tabStrip section*/

.k-content .ftp-settings-controls-container .ftp-settings-fts-row .storage-service-button {
    margin-left: 0.3rem;
    width: 8.5rem;
}

.k-content .ftp-settings-controls-container .ftp-settings-fts-row .storage-service-bg-button {
    width: 6rem;
    height: 2.2rem;
    border-radius: 0.25rem;
    background-color: #FFFFFF;
    color: #00424D;
    border: 1px solid #00424D;
}

.k-content .ftp-settings-controls-container .ftp-settings-fts-row .storage-service-bg-button-active {
    width: 6rem;
    height: 2.2rem;
    border-radius: 0.25rem;
    background-color: #00424D;
    color: #FFFFFF;

    border: 1px solid #00424D;
}

.onboarding-content .k-card .k-card-body .k-tabstrip .ftp-s3-tab .k-child-animation-container .ftp-settings-controls-container .fts-controls .ftps3-tab-button {
    background-color: var(--global-landing-page-color);
    color: white;
    border: none;
    font-family: Helvetica, Arial, "Roboto", sans-serif;
    border-radius: 0.25rem;
    height: 2.2rem;
    width: 12rem;
    padding: 0.5rem 1rem;
}

.onboarding-content .k-card .k-card-body .k-tabstrip .ftp-s3-tab .k-child-animation-container .ftp-settings-controls-container .fts-controls .non-match-ftps3-tab-button {
    background-color: var(--global-landing-page-color);
    color: white;
    border: none;
    font-family: Helvetica, Arial, "Roboto", sans-serif;
    border-radius: 0rem;
    height: 2.2rem;
    width: 12rem;
    padding: 0.5rem 1rem;
}

.onboarding-content .ftp-settings-fts-row .storage-service-dropdown-button-div .storage-service-bg {
    margin-left: 0.5rem
}

.tab-file-browser {
    width: 100%;
    max-width: 100%;
    margin: 1rem 0.5rem 0.5rem;
}

.tab-file-browser-inner-div {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.choose-a-file {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
    width: 100%;
}

.onboarding-content .k-tabstrip .tab-file-browser .file-browse-button,
.onboarding-content .k-tabstrip .tab-file-browser .choose-a-file {
    border-radius: 0.25rem;
}

.ftp-s3-tab-outer-div {
    padding-right: 0.5rem;
}

.nav-link-to-admin {
    text-decoration: none;
}

.onboarding-conn-buttons-container {
    display: flex;
    flex-direction: row;
}

.ftp-settings-fts-row .fts-controls .existing-connections-dropdown-div .onboarding-conn-buttons-container .manage-conn-button {
    margin-left: 0rem;
    margin-right: 0.5rem;
}

.non-matched-ftp-s3-button-outer-div {
    padding-top: 5px;
}

.k-button.manage-conn-button {
    background-color: var(--global-landing-page-color);
    color: #FFFFFF;
}

.k-button.manage-conn-button:hover {
    background-color: var(--global-landing-page-color);
    color: #FFFFFF;
}


