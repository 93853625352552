.onboarding-content {
    height: 95%;
    overflow-y: auto;
    margin-left: 1%;
    margin-right: 1%;
}
.segment-choice-button {
    /*margin: auto !important;*/
    /*width: 100% !important;*/
    /*justify-content: space-between;*/
    /*background-color: purple !important;*/
    color:purple !important;
    padding: 0rem;
    font-size: 1.6em !important;
    /*height: 8rem;*/
}
.k-button-text {
    font-size: 1.0em !important;
    font-family: Helvetica, Arial, "Roboto", sans-serif;
}
.seggy {
    margin: auto !important;
    width: 100%;
    background-color: #6ebdcf !important;
    color: white !important;
    height: 3rem;
}

.tabs-header {
    display: inline-flex;
    justify-content: space-evenly;
    margin: 0rem 0rem .6rem .2rem !important;
    /*margin: auto;*/

}

.container-fluid {
    /*margin-top: -2rem;*/

    /*padding-top: 1.2rem !important;*/

    padding-top: 1.2rem;
}

.k-button.submit-button {
    background-color: var(--global-landing-page-color);;
    color: #FFFFFF;
    width: 8rem;
    height: 2.2rem;
    border-radius: .25rem;
}
.k-button.submit-button:hover {
    background-color: #476c75;
}
.section-label {
    padding-top: 1rem;
    padding-left: 1rem;
    color: var(--global-landing-page-color);
    font-size: 1.1rem;
    font-weight: bold;
}
.label-prompts {
    padding-left: 3rem;
    padding-right: 2rem;
    font-size:18px;
    font-weight: bold;
}
button.create-segment-buttons {
    background-color: #FFFFFF;
    color: #00424D;
    height: 3rem;
    border-color: #00424D;
}
button.k-selected.create-segment-buttons {
    background-color: #476c75;
    color: #FFFFFF;
    height: 3rem;
    border-color: #FFFFFF;
}



.onboarding-content .k-card .k-card-body .k-tabstrip .k-content,
.onboarding-content .k-card .k-card-body .k-tabstrip .k-content .k-animation-container {
    width: 100%;
}

.k-drawer-content .onboarding-content .k-card .k-tabstrip .map-area-tab {
    padding: 0rem;
}

.onboarding-content .k-card .k-tabstrip .k-tabstrip-items .k-item .k-link {
    font-size: 1.1rem;
    color: var(--global-landing-page-color);;
}

.onboarding-content .k-card .k-tabstrip .k-tabstrip-items .k-state-active .k-link,
.onboarding-content .k-card .k-tabstrip .k-tabstrip-items .k-active .k-link {
    color: var(--global-landing-page-color);
    font-weight: bold;
}

.onboarding-content .k-card .k-card-body .k-active {
    display: block;
}

.onboarding-content .k-card .k-card-body .k-tabstrip .k-tabstrip-items .k-state-active {
    border-color: rgba(0,0,0,.08);
    color: #424242;
    background-color: #fff;
    border-bottom-color: transparent;
}

/*.onboarding-outer-div .onboarding-content .k-card .k-card-body {*/
/*    padding-top: 0rem;*/
/*}*/

.onboarding-outer-div {
    max-height: 90vh;
    max-width: 100vw;
}


.onboarding-outer-div .section-card{
    
}

.k-table-md {
    font-size: 14px;
    line-height: 1.0;
}



