.container {
    display:flex;
    width: 100%;
}
.column-1 {
    flex-shrink: 0;
    flex-basis: 50%;
}
.column-2 {
    flex-shrink: 0;
    flex-basis: 50%;
}

.about-audience-container {
    display: flex;
    flex-direction: row;

}

@media (max-width: 1130px) {
    .about-audience-container {
        flex-direction: column;
    }

    .about-audience-outer-div .about-audience-column-2 .k-form .notes-field-wrapper {
        margin-left: 0rem;
        margin-top: 1rem;
        /*width: 38rem;*/
    }

    .about-audience-outer-div .about-audience-segment-field,
    .about-audience-outer-div .about-audience-save-group-field {
        width: initial;
    }
}

.notes-field-wrapper {
    margin-left: 2rem;
}

.about-audience-column-1 {
    display: flex;
    flex-direction: column;
}

.about-audience-column-2 {
    flex-grow: 3;
}

.about-audience-save-to-group {
    padding-top: 1rem;
}

.about-audience-targeting {
    padding-top: 1rem;
}

.about-audience-notes-optional {
    color: #424242;
}

.labels {
    line-height: 1.25rem;
    color: rgba(0, 0, 0, 0.54);
    padding-bottom: .2rem;
    font-size: .9rem;
}
.labels-error {
    line-height: 1.25rem;
    color: red;
    padding-bottom: .2rem;
    font-size: .9rem;
}
.k-dropdownlist {
    /*width:10rem;*/
    border-radius: .25rem;
    height: 2.2rem;
}
.k-input.k-input-md.k-rounded-md.k-input-solid {
    border-style: solid;
    border-width: 1.5px;
    border-color: rgb(0 0 0 / 8%);
    background-color: whitesmoke;
    font-family: Helvetica, Arial, "Roboto", sans-serif;
    /*height: 2.2rem;*/
    border-radius: 0.25rem;
}
.k-input.k-textarea {
    border-style: solid;
    border-width: 1.5px;
    border-color: rgb(0 0 0 / 8%);
    background-color: whitesmoke;
}
.section-label {
    padding-top: 1rem;
    padding-left: 1rem;
    color: #476c75;
    font-size: 1.1rem;
    font-weight: bold;
}

.about-audience-outer-div .about-audience-card .about-audience-container {
    padding-left: 0rem;
    margin-left: 0rem;
}