.data-enhancement-outter-div .data-enhancement-content{
    margin-left: 1%;
    margin-right: 1%;
}

.data-enhancement-outter-div .card-header{
    font-size: 22px;
    padding: 15px 30px !important;
    color: rgb(0, 66, 77);
    font-weight: 600;
}