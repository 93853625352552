.reset-page-dialog .k-dialog .k-dialog-content {
    padding-top: 1.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.reset-page-dialog .k-dialog .k-dialog-buttongroup {
    padding: 1rem;
}

.reset-page-dialog .reset-password-new-container {
    margin-top: 1rem;
    padding-bottom: 0.25rem;
}

.reset-page-dialog .k-dialog .k-dialog-content .reset-password-new-container .new-password-field,
.reset-page-dialog .k-dialog .k-dialog-content .reset-password-confirm-new-container .confirm-password-field {
    border-radius: 0.25rem 0 0 0.25rem;
}



.reset-page-dialog .reset-password-confirm-new-container {
    margin-top: 0.5rem;
}

.reset-page-dialog .k-dialog .k-dialog-buttongroup .reset-password-button {
    background-color: #10191e;
}

/*.reset-page-dialog {*/
/*    width: 25rem;*/
/*}*/

.reset-page-dialog .new-password-hint {
    color: #585858;
    font-size: 12px;
    margin-top: .2rem;
}

.reset-password-success-container {
    display: flex;
    flex-direction: column;
}

.reset-page-dialog .reset-password-success-container .reset-successful-check-icon {
    color: green;
    /*padding: 2.5rem 2rem 2rem;*/
}

.reset-page-dialog .reset-password-success-container .field-label {
    align-self: center;
}

.reset-password-svg-icon-container {
    align-self: center;
    padding-bottom: 0.5rem;
    padding-top: 1rem;
}

.reset-page-dialog .pass-req-valid {
    color: green;
}

.reset-page-dialog .pass-req-valid {
    color: red;
}

.reset-page-dialog .password-field-wrapper {
    display: flex;
    flex-direction: row;
    margin-top: 0rem;
}

.reset-page-dialog .toggle-view-password {
    top: 0;
    left: 0;
    border-radius: 0 0.25rem 0.25rem 0;
}