.invalid-date-range{
    border-color: tomato !important;
    color: tomato !important;
}

.audience-design-outer-div {
    max-height: 90vh;
    max-width: 100vw;
}
.audience-design-content {
    height: 95%;
    overflow: auto;
    margin-left: 1%;
    margin-right: 1%;
}

.audience-design-spacer {
    padding-top: 1rem;
}

.audience-design-submit {
    padding-top: .5rem;
    float: right;
}
.audience-design-content .fa.fa-question-circle-o.tooltip-question-icon.save-button,
.audience-design-content .fa.fa-question-circle-o.tooltip-question-icon.save-as-button{
    top: -1rem;
   cursor: pointer;
}

.audience-design-outer-div .k-tabstrip .k-tabstrip-items .k-state-active .k-link,
.audience-design-outer-div .k-tabstrip .k-tabstrip-items .k-active .k-link {
    color: #00424d;
    font-weight: bold;
    font-family: Helvetica, Arial, 'Roboto', sans-serif;
}

.audience-design-outer-div .k-tabstrip-items .k-link {
    font-size: 1.1rem;
    color: #476c75;
    font-family: Helvetica, Arial, 'Roboto', sans-serif;
}
.tab-button-group-wrapper{
    display: flex;
    flex-direction: column;
}
.content-outer-wrapper .tab-button-group{
    display: flex;
    flex-direction: row;
    align-self: center;
}
.content-outer-wrapper .tab-button-group .k-button-group{
    flex-wrap: wrap;
}
.content-outer-wrapper .tab-button-group button{
    margin-right: 10px;
    height: 40px
}

.audience-design-content .selection-type-wrapper{
    float: right;
    font-size: 14px;
    padding-right: 1rem;
}

.audience-design-content .k-card-title{
    margin: 0;
}

.audience-design-content .k-form .k-form-field, .k-form .k-form-field-wrap{
    margin-top: 0;
}
.targeting-first-column-field{
    padding-right:15px
}

.k-daterangepicker .k-dateinput.k-input{
    height: 35px;
}